import { BaseService, Service } from "/@/cool";
@Service("myCustomerConversionRate")
class MyCustomerConversionRate extends BaseService {
    constructor() {
        super();

        let prefix = "";
        if (this.namespace) {
            prefix = this.namespace.replace(/\//g, ":") + ":";
        }

        this.permission["page"] = prefix + "page";
    }

    page(params: any) {
        return this.request({
            url: "/page",
            method: "POST",
            data: {
                ...params
            }
        });
    }

}

export default MyCustomerConversionRate;
