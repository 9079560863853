import { BaseService, Service } from "/@/cool";
@Service("oss")
class OSS extends BaseService {
	putSignURL(params: any) {
		return this.request({
			url: "/putSignURL",
			method: "POST",
			data: {
				...params
			}
		});
	}
	putSignURL2(params: any) {
		return this.request({
			url: "/putSignURL2",
			method: "POST",
			data: {
				...params
			}
		});
	}

	securet(params: any) {
		return this.request({
			url: "/securet",
			method: "POST",
			data: {
				...params
			}
		});
	}

	// 上传
	upload(url: string, params: any) {
		return this.request({
			url: url,
			method: "PUT",
			data: params,
			headers: {
				"Content-Type": "application/octet-stream"
			}
		});
	}
}
export default OSS;
