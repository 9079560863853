import { Times } from "../dict/time";
import dayjs from "dayjs";

export function transfter(data: any, timezone = "Asia/Shanghai") {
	if (Array.isArray(data)) {
		transferArray(data, timezone);
	} else if (data) {
		transfterObj(data, timezone);
	}
}

export function transferArray(data: any, timezone = "Asia/Shanghai") {
	data.forEach((item: any) => {
		transfterObj(item, timezone);
	});
}

export function transfterObj(data: any, timezone = "Asia/Shanghai") {
	Times.forEach((key: string) => {
		if (data[key]) {
			data[key] = transferValue(data[key], timezone);
		}
	});
}

const AsiaUTCOffset = 8 * 60;

export function transferValue(value: any, timezone = "Asia/Shanghai") {
	if (value) {
		// 由于时间是东八区的时间，所以炫耀先转换称本初子午线的时间，然后再转换成对应时区的时间
		const d = dayjs(value);
		const diffUctOffset = d.utcOffset() - AsiaUTCOffset;
		return d.add(diffUctOffset, "minute").tz(timezone).format("YYYY-MM-DD HH:mm:ss");
	}
	return value;
}
