import { BaseService, Service } from "/@/cool";
@Service("eduQuestionsDetail")
class EduQuestionsDetail extends BaseService { 
    constructor() {
    super();

    let prefix = "";
    if (this.namespace) {
        prefix = this.namespace.replace(/\//g, ":") + ":";
    }

    this.permission["add"] = prefix + "add";
    this.permission["update"] = prefix + "update";
    this.permission["page"] = prefix + "page";
    this.permission["list"] = prefix + "list";
    this.permission["info"] = prefix + "info";
}}
export default EduQuestionsDetail;
