import { BaseService, Service, Permission } from "/@/cool";
@Service("takephotoOrder")
class TakephotoOrder extends BaseService {
	constructor() {
		super();

		let prefix = "";
		if (this.namespace) {
			prefix = this.namespace.replace(/\//g, ":") + ":";
		}

		this.permission["page"] = prefix + "page";
		this.permission["list"] = prefix + "list";
		this.permission["info"] = prefix + "info";
		this.permission["infoWithOrderId"] = prefix + "infoWithOrderId";

		this.permission["modelingMake"] = prefix + "modelingMake";
		this.permission["takephotoPreviewImages"] = prefix + "takephotoPreviewImages";
		this.permission["takephotoAllImages"] = prefix + "takephotoAllImages";
		this.permission["retake"] = prefix + "retake";
		this.permission["customerService"] = prefix + "customerService";
		this.permission["canPostMakeModel"] = prefix + "canPostMakeModel";
		this.permission["cancel"] = prefix + "cancel";
		this.permission["shopTicketInfo"] = prefix + "shopTicketInfo";
		this.permission["printedOrderPage"] = prefix + "printedOrderPage";
		this.permission["previewScene"] = prefix + "previewScene";
		this.permission["previewModel"] = prefix + "previewModel";
		this.permission["downloadModel"] = prefix + "downloadModel";
		this.permission["getStudioType"] = prefix + "getStudioType";
		this.permission["postDelete"] = prefix + "postDelete";
		this.permission["pageWithPrintOrder"] = prefix + "pageWithPrintOrder";
		this.permission["digitalType"] = prefix + "digitalType"; // 类型
		this.permission["shakeCheckImage"] = prefix + "shakeCheckImage"; // 类型
		this.permission["previewAuth"] = prefix + "previewAuth"; // 3d预览授权
		this.permission["exportShopOrder"] = prefix + "exportShopOrder"; // 导出订单
		this.permission["cancelOrderPage"] = prefix + "cancelOrderPage"; // 导出订单
	}
	exportShopOrder(params: any) {
		return this.request({
			url: "/exportShopOrder",
			method: "POST",
			data: { ...params }
		});
	}
	downloadModel(params: any) {
		return this.request({
			url: "/downloadModel",
			method: "POST",
			data: { ...params }
		});
	}

	// 3d预览授权
	previewAuth(params: any) {
		return this.request({
			url: "/previewAuth",
			method: "POST",
			data: { ...params }
		});
	}

	modelingMake(params: any) {
		return this.request({
			url: "/modelingMake",
			method: "POST",
			data: { ...params }
		});
	}

	takephotoPreviewImages(params: any) {
		return this.request({
			url: "/takephotoPreviewImages",
			method: "POST",
			data: { ...params }
		});
	}

	takephotoAllImages(params: any) {
		return this.request({
			url: "/takephotoAllImages",
			method: "POST",
			data: { ...params }
		});
	}
	retake(params: any) {
		return this.request({
			url: "/retake",
			method: "POST",
			data: { ...params }
		});
	}

	customerService(params: any) {
		return this.request({
			url: "/customerService",
			method: "POST",
			data: { ...params }
		});
	}

	canPostMakeModel(params: any) {
		return this.request({
			url: "/canPostMakeModel",
			method: "POST",
			data: { ...params }
		});
	}

	cancel(params: any) {
		return this.request({
			url: "/cancel",
			method: "POST",
			data: { ...params }
		});
	}

	shopTicketInfo(params: any) {
		return this.request({
			url: "/shopTicketInfo",
			method: "POST",
			data: { ...params }
		});
	}

	// 已完成打印的订单
	printedOrderPage(params: any) {
		return this.request({
			url: "/printedOrderPage",
			method: "POST",
			data: { ...params }
		});
	}

	getStudioType(params: any) {
		return this.request({
			url: "/getStudioType",
			method: "POST",
			data: { ...params }
		});
	}

	postDelete(params: any) {
		return this.request({
			url: "/postDelete",
			method: "POST",
			data: { ...params }
		});
	}

	pageWithPrintOrder(params: any) {
		return this.request({
			url: "/pageWithPrintOrder",
			method: "POST",
			data: { ...params }
		});
	}
	infoWithOrderId(params: any) {
		return this.request({
			url: "/infoWithOrderId",
			method: "POST",
			data: { ...params }
		});
	}

	digitalType(data: {}) {
		return this.request({
			url: "/digitalType",
			method: "POST",
			data: {
				...data
			}
		});
	}

	shakeCheckImage(data: {}) {
		return this.request({
			url: "/shakeCheckImage",
			method: "POST",
			data: {
				...data
			}
		});
	}

	// 取消订单
	cancelOrderPage(data: {}) {
		return this.request({
			url: "/cancelOrderPage",
			method: "POST",
			data: {
				...data
			}
		});
	}
}
export default TakephotoOrder;
