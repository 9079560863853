import { BaseService, Service } from "/@/cool";
@Service("wechatApplyTakephoto")
class WechatApplyTakephoto extends BaseService {
	constructor() {
		super();

		let prefix = "";
		if (this.namespace) {
			prefix = this.namespace.replace(/\//g, ":") + ":";
		}

		this.permission["page"] = prefix + "page";
		this.permission["cancel"] = prefix + "cancel";
	}

	cancel(params: any) {
		return this.request({
			url: "/cancel",
			method: "POST",
			data: {
				...params
			}
		});
	}
}
export default WechatApplyTakephoto;
