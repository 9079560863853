import { defineStore } from "pinia";
import { ref } from "vue";
import { href, storage } from "/@/cool/utils";

interface Shop {
	id: number;
	shop_name: string;
	production_cost_reserve_fund: number;
	[key: string]: any;
}

// 本地缓存
const data = storage.info();

export const useShopStore = defineStore("shop", function () {
	// 门店信息
	const info = ref<Shop | null>(data.shopInfo);

	// 设置门店信息
	function set(value: any) {
		info.value = value;
		storage.set("shopInfo", value);
	}

	// 清除门店
	function clear() {
		storage.remove("shopInfo");
		info.value = null;
	}

	// 获取门店信息
	async function get() {
		return info.value;
	}

	return {
		info,
		get,
		set,
		clear
	};
});
