import { BaseService, Service } from "/@/cool";
@Service("wlOrders")
class WlOrders extends BaseService {
	constructor() {
		super();
		let prefix = "";
		if (this.namespace) {
			prefix = this.namespace.replace(/\//g, ":") + ":";
		}

		this.permission["myPage"] = prefix + "myPage";
		this.permission["page"] = prefix + "page";
		this.permission["pay"] = prefix + "pay";
		this.permission["myPage"] = prefix + "myPage";
		this.permission["confirmReceive"] = prefix + "confirmReceive";
		this.permission["ship"] = prefix + "ship";
		this.permission["showLogistical"] = prefix + "showLogistical";
	}

	pay(data: {}) {
		return this.request({
			url: "/pay",
			method: "POST",
			data
		});
	}
	myPage(data: {}) {
		return this.request({
			url: "/myPage",
			method: "POST",
			data
		});
	}
	confirmReceive(data: {}) {
		return this.request({
			url: "/confirmReceive",
			method: "POST",
			data
		});
	}
	ship(data: {}) {
		return this.request({
			url: "/ship",
			method: "POST",
			data
		});
	}
	showLogistical(data: {}) {
		return this.request({
			url: "/showLogistical",
			method: "POST",
			data
		});
	}
	cancel(data: {}) {
		return this.request({
			url: "/cancel",
			method: "POST",
			data
		});
	}
}
export default WlOrders;
