export const routeMap: Object = {
	"/myCustomerP3dLogExpedited": "/expeditedOrder",
	"/myCustomerP3dLog": "/takephotoOrder",
	"/myAddPrintOrder": "/addPrintOrder",
	"/myModelCustomerService": "/customerService",
	"/myModelExpressOrder": "/modelExpressOrder",
	"/wlGoodsShop": "/shoppingMall",
	"/wlMyOrder": "/shoppingOrder",
	"/wlShoppingCart": "/shoppingCart"
};
