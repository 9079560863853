import { proxy } from "./proxy";

export default {
	host: proxy["/oem"].target,
	// 请求地址
	baseUrl: "https://pm.api.sruid.com/admin",
	oss: {
		website: "https://www.sruid.com",
		"3dview": "https://pm.sruid.com",
		webwechat: "https://pm.sruid.com",
		securedata: "https://pm.sruid.com"
	},
	msgWebsocket: {
		WEBSOCKETADDRESS: "wss://pm.api.sruid.com"
	},
	weightWs: "ws://localhost:10999/ws",
	menuIgnore: [],
	wechatApi: "https:/pm.sruid.com"
};
