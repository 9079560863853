<script lang="ts">
					import { defineComponent } from 'vue'
					export default defineComponent({
						name: "undefined"
					})
				</script><template>
	<el-config-provider :locale="locale">
		<router-view />
	</el-config-provider>
</template>

<script lang="ts" setup>
import { ElConfigProvider } from "element-plus";
import zhCn from "element-plus/lib/locale/lang/zh-cn";
import zhTW from "element-plus/lib/locale/lang/zh-tw";
import en from "element-plus/lib/locale/lang/en";
import es from "element-plus/lib/locale/lang/es";
import { useTranslation } from "i18next-vue";
import { computed, watch } from "vue";
import { useBase } from "/$/base";
import { getOemSetting } from "/@//utils/oem";

import * as i18n from "./utils/i18n";
const { i18next, t } = useTranslation();
i18n.set(t);
const { lang } = useBase();

function transTitle() {
	if (getOemSetting("brand_name")) {
		document.title = getOemSetting("brand_name");
	} else {
		document.title = i18next.t("website_title");
	}
}

watch(
	() => i18next.language,
	(val: any) => {
		// console.log("set lang", val);
		lang.set(i18next.language);
		transTitle();
	},
	{ immediate: true }
);

const locale = computed(() => {
	lang.set(i18next.language);
	switch (i18next.language) {
		case "zh-CN":
			return zhCn;
		case "en":
			return en;
		case "es":
		case "es-AR":
		case "es-BO":
		case "es-CL":
		case "es-ES":
		case "es-MX":
		case "es-AR":
			return es;
		case "zh-TW":
		case "zh-HK":
			return zhTW;
		default:
			return zhCn;
	}
});
</script>
<style lang="scss" src="./assets/css/index.scss"></style>
