import { BaseService, Service } from "/@/cool";

@Service("equipment")
class Equipment extends BaseService {
	listForShop(params: any) {
		return this.request({
			url: "/listForShop",
			method: "POST",
			data: {
				...params
			}
		});
	}

	takephoto(params: any) {
		return this.request({
			url: "/takephoto",
			method: "POST",
			data: { ...params }
		});
	}

	videoKeyToken(params: any) {
		return this.request({
			url: "/videoKeyToken",
			method: "POST",
			data: { ...params }
		});
	}

	uuid(params: any) {
		return this.request({
			url: "https://192.168.88.2:8200/client/uuid",
			method: "POST",
			data: { ...params }
		});
	}
}
export default Equipment;
