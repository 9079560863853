import { BaseService, Service } from "/@/cool";
@Service("wlSkus")
class WlSkus extends BaseService {
	getSpecList(data: {}) {
		return this.request({
			url: "/getSpecList",
			method: "POST",
			data
		});
	}
	existBySpecIDs(data: {}) {
		return this.request({
			url: "/existBySpecIDs",
			method: "POST",
			data
		});
	}
}
export default WlSkus;
