import { defineStore } from "pinia";
import { ref } from "vue";
import { storage } from "/@/cool/utils";

interface Watermask {
	isShade: boolean;
	//定义浮点数
	shadeSize: Float32Array;
}

// 本地缓存
const data = storage.info();

export const useWatermaskStore = defineStore("watermask", function () {
	//
	const info = ref<Watermask | null>(data.watermask);
	//
	function set(value: any) {
		info.value = value;
		storage.set("watermask", value);
	}
	// 获取门店信息
	async function get() {
		return info.value;
	}

	function setImage() {
		set({ isShade: true, shadeSize: 0.5 });
		return () => {
			set({ isShade: false });
		};
	}

	return {
		info,
		get,
		set,
		setImage
	};
});
