import { BaseService, Service } from "/@/cool";
@Service("modelExpressOrderDetail")
class ModelExpressOrderDetail extends BaseService {
	constructor() {
		super();

		let prefix = "";
		if (this.namespace) {
			prefix = this.namespace.replace(/\//g, ":") + ":";
		}

		this.permission["info"] = prefix + "info";
		this.permission["page"] = prefix + "page";
	}
}
export default ModelExpressOrderDetail;
