import { BaseService, Service } from "/@/cool";
@Service("wlGoods")
class WlGoods extends BaseService {
	constructor() {
		super();
		let prefix = "";
		if (this.namespace) {
			prefix = this.namespace.replace(/\//g, ":") + ":";
		}

		this.permission["page"] = prefix + "page";
		this.permission["getDetail"] = prefix + "getDetail";
		this.permission["updateStatus"] = prefix + "updateStatus";
		this.permission["existPriceZero"] = prefix + "existPriceZero";
	}

	getDetail(data: {}) {
		return this.request({
			url: "/getDetail",
			method: "POST",
			data
		});
	}
	updateStatus(data: {}) {
		return this.request({
			url: "/updateStatus",
			method: "POST",
			data
		});
	}
	existPriceZero(data: {}) {
		return this.request({
			url: "/existPriceZero",
			method: "POST",
			data
		});
	}
}
export default WlGoods;
