import { BaseService, Service } from "/@/cool";
@Service("oamCheckAndSetup")
class OamCheckAndSetup extends BaseService {
	// constructor() {
	// 	super();

	// 	let prefix = "";
	// 	if (this.namespace) {
	// 		prefix = this.namespace.replace(/\//g, ":") + ":";
	// 	}

	// 	//this.permission["unbindingWx"] = prefix + "unbindingWx";
	// }

	//检测是否不要经过设备检测等一系列的流程
	checkIsFilterShopId(data: any) {
		return this.request({
			url: "/checkIsFilterShopId",
			method: "POST",
			data
		});
	}
	//检测影棚设备是否授权完成
	getCheckDevicesIp(data: any) {
		return this.request({
			url: "/getCheckDevicesIp",
			method: "POST",
			data
		});
	}

	checkShopSetupStatus(data: any) {
		return this.request({
			url: "/checkShopSetupStatus",
			method: "POST",
			data
		});
	}

	updateDevices(data: any) {
		return this.request({
			url: "/updateDevices",
			method: "POST",
			data
		});
	}
	
	checkMasterServerAuth(data: any) {
		return this.request({
			url: "/checkMasterServerAuth",
			method: "POST",
			data
		});
	}

	checkDevices (data: any) {
		return this.request({
			url: "/checkDevices",
			method: "POST",
			data
		});
	}

	recordShopExeTime(data: any) {
		return this.request({
			url: "/recordShopExeTime",
			method: "POST",
			data
		});
	}
}
export default OamCheckAndSetup;
