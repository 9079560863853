const defaultInterval = 1000 * 60 * 5; // 5分钟检测一次

const DEV = "dev";
const OEM = "oem";
const OVERSEA = "oversea";
const PROD = "prod";
const GRAY = "gray";
const SHOP = "shop";
const UAT = "uat";

export const versionDict = [
	{
		interval: 1000 * 5, // 5分钟检测一次
		mode: DEV,
		version: "1.0.0" // 当前版本号，每次发布时需要修改版本号
	},
	{
		interval: 1000 * 60 * 5, // 5分钟检测一次
		mode: OVERSEA,
		version: "1.0.0" // 当前版本号，每次发布时需要修改版本号
	},
	{
		interval: 1000 * 60 * 5, // 5分钟检测一次
		mode: PROD,
		version: "1.0.0" // 当前版本号，每次发布时需要修改版本号
	},
	{
		interval: 1000 * 60 * 5, // 5分钟检测一次
		mode: OEM,
		version: "1.0.0" // 当前版本号，每次发布时需要修改版本号
	},
	{
		interval: 1000 * 60 * 5, // 5分钟检测一次
		mode: GRAY,
		version: "1.0.0" // 当前版本号，每次发布时需要修改版本号
	},
	{
		interval: 1000 * 60 * 5, // 5分钟检测一次
		mode: SHOP,
		version: "1.0.0" // 当前版本号，每次发布时需要修改版本号
	},
	{
		interval: 1000 * 60 * 5, // 5分钟检测一次
		mode: UAT,
		version: "1.0.0" // 当前版本号，每次发布时需要修改版本号
	}
];

// 获取当前版本号
export function getCurrentVersion() {
	const mode = import.meta.env.MODE;
	const dict = versionDict.find((item) => item.mode === mode);
	return dict?.version;
}

export function getInterval() {
	const mode = import.meta.env.MODE;
	const dict = versionDict.find((item) => item.mode === mode);
	return dict?.interval || defaultInterval;
}

export function compareVersion(newVersion: String): boolean {
	// 版本号比较，如果当前版本号小于最新版本号，则返回true
	const currentVersion = getCurrentVersion();
	if (!currentVersion) {
		return false;
	}
	const currentVersionArr = currentVersion.split(".");
	const newVersionArr = newVersion.split(".");
	for (let i = 0; i < currentVersionArr.length; i++) {
		if (parseInt(currentVersionArr[i]) < parseInt(newVersionArr[i])) {
			return true;
		}
	}
	return false;
}
