import { BaseService, Service } from "/@/cool";
@Service("messageReceveDetailRecord")
class MessageReceveDetailRecord extends BaseService {
	//获取消息列表
	getUserMessageList(data: {}) {
		return this.request({
			url: "/persionList",
			method: "POST",
			data
		});
	}

	page(data: { page?: number; size?: number; [key: string]: any }) {
		return this.request({
			url: "/page",
			method: "POST",
			data
		});
	}
}
export default MessageReceveDetailRecord;
