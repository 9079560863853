export const Create_time = "create_time";
export const Update_time = "update_time";
export const Delete_time = "delete_time";
export const Takephoto_time = "takephoto_time";
export const Apply_time = "apply_time";
export const Cancel_time = "cancel_time";

export const CreateTime = "createTime";
export const UpdateTime = "updateTime";
export const DeleteTime = "deleteTime";
export const TakephotoTime = "takephotoTime";
export const ApplyTime = "applyTime";
export const CancelTime = "cancelTime";

export const CreatedAt = "created_at";
export const UpdatedAt = "updated_at";
export const StartValidTime = "start_valid_time";
export const ExpiredTime = "expired_time";
export const CompletedAt = "completed_at";
export const ShippedAt = "shipped_at";
export const ConfirmTakeTime = "confirm_take_time";

export const Times: any[] = [
	Create_time,
	Update_time,
	Delete_time,
	Takephoto_time,
	Apply_time,
	Cancel_time,

	CreateTime,
	UpdateTime,
	DeleteTime,
	TakephotoTime,
	ApplyTime,
	CancelTime,

	CreatedAt,
	UpdatedAt,
	StartValidTime,
	ExpiredTime,
	CompletedAt,
	ShippedAt,
	ConfirmTakeTime
];
