import { BaseService, Service } from "/@/cool";
@Service("oem")
class Oem extends BaseService {
	constructor() {
		super();

		let prefix = "";
		if (this.namespace) {
			prefix = this.namespace.replace(/\//g, ":") + ":";
		}

		this.permission["add"] = prefix + "add";
		this.permission["update"] = prefix + "update";
		this.permission["page"] = prefix + "page";
		this.permission["list"] = prefix + "list";
		this.permission["info"] = prefix + "info";
	}

	//根据域名获取oem信息
	info(data: any) {
		return this.request({
			url: "/info",
			method: "POST",
			data
		});
	}
}
export default Oem;
