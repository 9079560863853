import { BaseService, Service } from "/@/cool";
@Service("couponLog")
class CouponLog extends BaseService {
    constructor() {
		super();

		let prefix = "";
		if (this.namespace) {
			prefix = this.namespace.replace(/\//g, ":") + ":";
		}

		this.permission["add"] = prefix + "add";
		this.permission["update"] = prefix + "update";
		this.permission["page"] = prefix + "page";
		this.permission["list"] = prefix + "list";
		this.permission["info"] = prefix + "info";
		this.permission["export"] = prefix + "export";
		this.permission["infoCouponTracking"] = prefix + "infoCouponTracking";
		this.permission["InfoCouponTrackingByStore"] = prefix + "InfoCouponTrackingByStore";
	}	

	InfoCouponTracking(params: any) {
		return this.request({
			url: "/infoCouponTracking",
			method: "POST",
			data: {
				...params
			}
		});
	}

	InfoCouponTrackingByStore(params: any) {
		return this.request({
			url: "/infoCouponTracking",
			method: "POST",
			data: {
				...params
			}
		});
	}
	
}

export default CouponLog;
