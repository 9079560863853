import { BaseService, Service } from "/@/cool";
@Service("coupon")
class Coupon extends BaseService {
	constructor() {
		super();

		let prefix = "";
		if (this.namespace) {
			prefix = this.namespace.replace(/\//g, ":") + ":";
		}

		this.permission["add"] = prefix + "add";
		this.permission["update"] = prefix + "update";
		this.permission["page"] = prefix + "page";
		this.permission["list"] = prefix + "list";
		this.permission["info"] = prefix + "info";
		this.permission["export"] = prefix + "export";
		this.permission["deliveryCoupon"] = prefix + "deliveryCoupon";
		this.permission["effectCoupon"] = prefix + "effectCoupon";
		this.permission["convertAddCoupon"] = prefix + "convertAddCoupon";
		this.permission["accountCoupon"] = prefix + "accountCoupon";
		this.permission["settleCoupon"] = prefix + "settleCoupon";
		this.permission["checkCoupon"] = prefix + "checkCoupon";
		this.permission["exportCoupon"] = prefix + "exportCoupon";
		this.permission["couponImprest"] = prefix + "couponImprest";
		this.permission["batchCouponPayment"] = prefix + "batchCouponPayment";
		this.permission["filterMsgButton"] = prefix + "filterMsgButton";
	}

	//验证卡券
	checkCoupon(data: {}) {
		return this.request({
			url: "/checkCoupon",
			method: "POST",
			data
		});
	}

	//使用卡券
	useCOupon(data: {}) {
		return this.request({
			url: "/useCoupon",
			method: "POST",
			data
		});
	}

	//传入后端格式化
	convertAddCoupon(couponList: any) {
		const newCouponList: any[] = [];
		Object.values(couponList).forEach((item: any) => {
			if (item.selected) {
				newCouponList.push({
					id: item.id,
					code: item.code,
					password: item.password
				});
			}
		});
		return newCouponList;
	}

	//设置卡券状态为导出
	exportCoupon(data: {}) {
		return this.request({
			url: "/exportCoupon",
			method: "POST",
			data
		});
	}

	//设置卡券状态为发货
	deliveryCoupon(data: {}) {
		return this.request({
			url: "/deliveryCoupon",
			method: "POST",
			data
		});
	}

	//设置卡券状态为启用
	effectCoupon(data: {}) {
		return this.request({
			url: "/effectCoupon",
			method: "POST",
			data
		});
	}

	//设置卡券状态为结算
	accountCoupon(data: {}) {
		return this.request({
			url: "/accountCoupon",
			method: "POST",
			data
		});
	}

	//批量结算卡券
	settleCoupon(data: {}) {
		return this.request({
			url: "/settleCoupon",
			method: "POST",
			data
		});
	}

	//批量卡券转备用金
	couponImprest(data: {}) {
		return this.request({
			url: "/couponImprest",
			method: "POST",
			data
		});
	}

	//批量卡券提现
	batchCouponPayment(data: {}) {
		return this.request({
			url: "/batchCouponPayment",
			method: "POST",
			data
		});
	}
}

export default Coupon;
