import { BaseService, Service } from "/@/cool";
@Service("wechatPicVideo")
class WechatPicVideo extends BaseService {
	constructor() {
		super();

		let prefix = "";
		if (this.namespace) {
			prefix = this.namespace.replace(/\//g, ":") + ":";
		}
		this.permission["add"] = prefix + "add";
		this.permission["info"] = prefix + "info";
		this.permission["page"] = prefix + "page";
		this.permission["delete"] = prefix + "delete";
		this.permission["newPrintedOrderPage"] = prefix + "newPrintedOrderPage";
		this.permission["selectOrderId"] = prefix + "selectOrderId";
		this.permission["signedUrlsById"] = prefix + "signedUrlsById";
	}

	newPrintedOrderPage(params: any) {
		return this.request({
			url: "/newPrintedOrderPage",
			method: "GET",
			params
		});
	}

	selectOrderId(params: any) {
		return this.request({
			url: "/selectOrderId",
			method: "GET",
			params
		});
	}

	// 选中样品分类，让其在官网（精选产品）展示
	signedUrlsById(data: any) {
		return this.request({
			url: "/signedUrlsById",
			method: "POST",
			data
		});
	}
}
export default WechatPicVideo;
