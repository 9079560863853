import { BaseService, Service } from "/@/cool";

@Service("appVersion")
class AppVersion extends BaseService {
	constructor() {
		super();

		let prefix = "";
		if (this.namespace) {
			prefix = this.namespace.replace(/\//g, ":") + ":";
		}

		this.permission["add"] = prefix + "add";
		this.permission["update"] = prefix + "update";
		this.permission["info"] = prefix + "info";
		this.permission["latest"] = prefix + "latest";
		this.permission["list"] = prefix + "list";
		this.permission["page"] = prefix + "page";
		this.permission["publish"] = prefix + "publish";
	}
	latest(data: any) {
		return this.request({
			url: "/latest",
			method: "POST",
			data
		});
	}
	publish(data: any) {
		return this.request({
			url: "/publish",
			method: "POST",
			data
		});
	}
}

export default AppVersion;
