import { SimpleTFunction } from "i18next-vue";

let translation: SimpleTFunction;

export function set(f: SimpleTFunction) {
	translation = f;
}

export function t(key: string, defaultValue: string) {
	if (translation) {
		let ret = translation(key, defaultValue);
		//console.log("t:", key, defaultValue, ret);
		return ret;
	}
	return defaultValue;
}
