import { defineStore } from "pinia";
import { ref } from "vue";
import { storage } from "/@/cool/utils";

interface ShakeCheck {
	[key: number]: boolean;
}

// 本地缓存
const data = storage.info();

export const useShakeCheckStore = defineStore("shakeCheck", function () {
	// 用户信息
	const info = ref<ShakeCheck>(data.shakeCheck || {});

	function set(key: any) {
		info.value[key] = true;
		storage.set("shakeCheck", info.value);
	}

	function get(key: number): boolean {
		return info.value[key];
	}

	function remove(key: number) {
		delete info.value[key];
		storage.set("shakeCheck", info.value);
	}

	return {
		info,
		set,
		get,
		remove
	};
});
