import { BaseService, Service } from "/@/cool";
@Service("photoStudioSetup")
class PhotoStudioSetup extends BaseService {
	constructor() {
		super();

		let prefix = "";
		if (this.namespace) {
			prefix = this.namespace.replace(/\//g, ":") + ":";
		}

		this.permission["skipCheck"] = prefix + "skipCheck";
		this.permission["waitingCheckDevices"] = prefix + "waitingCheckDevices";
		this.permission["status"] = prefix + "status";
		this.permission["passCheck"] = prefix + "passCheck";
		this.permission["checkOrNew"] = prefix + "checkOrNew";
		this.permission["checkDevices"] = prefix + "checkDevices";
		this.permission["recordUseApp"] = prefix + "recordUseApp";
	}

	//检测是否不要经过设备检测等一系列的流程, checkIsFilterShopId
	skipCheck(data: any) {
		return this.request({
			url: "/skipCheck",
			method: "POST",
			data
		});
	}

	//检测影棚设备是否授权完成, GetCheckDevicesIp
	waitingCheckDevices(data: any) {
		return this.request({
			url: "/waitingCheckDevices",
			method: "POST",
			data
		});
	}

	// CheckShopSetupStatus
	status(data: any) {
		return this.request({
			url: "/status",
			method: "POST",
			data
		});
	}

	// UpdateDevices
	passCheck(data: any) {
		return this.request({
			url: "/passCheck",
			method: "POST",
			data
		});
	}

	// checkMasterServerAuth
	checkOrNew(data: any) {
		return this.request({
			url: "/checkOrNew",
			method: "POST",
			data
		});
	}

	checkDevices(data: any) {
		return this.request({
			url: "/checkDevices",
			method: "POST",
			data
		});
	}

	// recordShopExeTime
	recordUseApp(data: any) {
		return this.request({
			url: "/recordUseApp",
			method: "POST",
			data
		});
	}
}
export default PhotoStudioSetup;
