import { proxy } from "./proxy";

export default {
	host: proxy["/uat"].target,
	// 请求地址
	baseUrl: "http://172.31.1.254:18199/admin/",
	oss: {
		website: "https://SRUIDD3d-test.oss-cn-shanghai.aliyuncs.com",
		"3dview": "https://SRUIDD3d-test.oss-cn-shanghai.aliyuncs.com",
		webwechat: "https://SRUIDD3d-test.oss-cn-shanghai.aliyuncs.com",
		securedata: "https://SRUIDD3d-test.oss-cn-shanghai.aliyuncs.com"
	},
	msgWebsocket: {
		WEBSOCKETADDRESS: "ws://172.31.1.254:18199"
	},
	weightWs: "ws://localhost:10999/ws",
	menuIgnore: [],
	watermask: {
		title: "速哇3D真人手办"
	}
};
