import { BaseService, Service, Permission } from "/@/cool";

@Service("baseSysUser")
class SysUser extends BaseService {
	constructor() {
		super();

		let prefix = "";
		if (this.namespace) {
			prefix = this.namespace.replace(/\//g, ":") + ":";
		}

		this.permission["add"] = prefix + "add";
		this.permission["update"] = prefix + "update";
		this.permission["delete"] = prefix + "delete";
		this.permission["page"] = prefix + "page";
		this.permission["list"] = prefix + "list";
		this.permission["info"] = prefix + "info";
		this.permission["move"] = prefix + "move";
		this.permission["updateStatus"] = prefix + "updateStatus";
	}

	@Permission("move")
	move(data: any) {
		return this.request({
			url: "/move",
			method: "POST",
			data
		});
	}

	updateStatus(data: any) {
		if (data.status) {
			data.status = 1;
		} else if (data.status == false) {
			data.status = 0;
		}

		return this.request({
			url: "/updateStatus",
			method: "POST",
			data
		});
	}
}

export default SysUser;
