import { useBaseStore } from "/$/base/store";
import {transferValue} from "./timezone";

export function registerTransferValue(app: any) {
    Object.defineProperty(String.prototype, "$tz", {
        get: function $tz() {
            const { timezone } = useBaseStore();
            return transferValue(this, timezone.get());
        }
    });

    app.config.globalProperties.$tz = function(val: any) {
        const { timezone } = useBaseStore();
        return transferValue(val, timezone.get());
    };
}
