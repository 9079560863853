import { BaseService, Service } from "/@/cool";
@Service("wlShoppingCart")
class WlShoppingCart extends BaseService {
	constructor() {
		super();
		let prefix = "";
		if (this.namespace) {
			prefix = this.namespace.replace(/\//g, ":") + ":";
		}

		this.permission["page"] = prefix + "page";
		this.permission["addOne"] = prefix + "addOne";
		this.permission["updateInfo"] = prefix + "updateInfo";
		this.permission["delete"] = prefix + "delete";
	}

	addOne(data: {}) {
		return this.request({
			url: "/addOne",
			method: "POST",
			data
		});
	}
	updateInfo(data: {}) {
		return this.request({
			url: "/updateInfo",
			method: "POST",
			data
		});
	}
}
export default WlShoppingCart;
