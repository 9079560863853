import { BaseService, Service } from "/@/cool";
@Service("eduLearnCoursor")
class EduLearnCoursor extends BaseService { 
    constructor() {
		super();

		let prefix = "";
		if (this.namespace) {
			prefix = this.namespace.replace(/\//g, ":") + ":";
		}

		this.permission["add"] = prefix + "add";
		this.permission["update"] = prefix + "update";
		this.permission["page"] = prefix + "page";
		this.permission["list"] = prefix + "list";
		this.permission["info"] = prefix + "info";
		this.permission["myEduCoursur"] = prefix + "myEduCoursur";
	}

	myEduCoursur(data: any){
		return this.request({
			url: "/myEduCoursur",
			method: "POST",
			data,
		});
	}
}
export default EduLearnCoursor;
