import { BaseService, Service } from "/@/cool";

@Service("baseSysRole")
class SysRole extends BaseService {
	constructor() {
		super();

		let prefix = "";
		if (this.namespace) {
			prefix = this.namespace.replace(/\//g, ":") + ":";
		}

		this.permission["add"] = prefix + "add";
		this.permission["update"] = prefix + "update";
		this.permission["page"] = prefix + "page";
		this.permission["list"] = prefix + "list";
		this.permission["info"] = prefix + "info";
		this.permission["refreshPerms"] = prefix + "refreshPerms";
	}

	refreshPerms(roleId: number) {
		return this.request({
			url: "/refreshPerms",
			method: "POST",
			data: {
				id: roleId
			}
		});
	}
}

export default SysRole;
