import { BaseService, Service } from "/@/cool";
@Service("goods")
class Goods extends BaseService {
	constructor() {
		super();

		let prefix = "";
		if (this.namespace) {
			prefix = this.namespace.replace(/\//g, ":") + ":";
		}

		this.permission["calculateTotalAmount"] = prefix + "calculateTotalAmount";
		this.permission["calculateTotalAmountV2"] = prefix + "calculateTotalAmountV2";
		this.permission["listWithAttr"] = prefix + "listWithAttr";
		this.permission["addGoods"] = prefix + "addGoods";
		this.permission["calculateTotalAmountV2List"] = prefix + "calculateTotalAmountV2List";
	}

	calculateTotalAmount(params: any) {
		return this.request({
			url: "/calculateTotalAmount",
			method: "POST",
			data: {
				...params
			}
		});
	}
	calculateTotalAmountV2(params: any) {
		return this.request({
			url: "/calculateTotalAmountV2",
			method: "POST",
			data: {
				...params
			}
		});
	}
	listWithAttr(params: any) {
		return this.request({
			url: "/listWithAttr",
			method: "POST",
			data: {
				...params
			}
		});
	}
	addGoods(params: any) {
		return this.request({
			url: "/addGoods",
			method: "POST",
			data: {
				...params
			}
		});
	}

	calculateTotalAmountV2List(params: any) {
		return this.request({
			url: "/calculateTotalAmountV2List",
			method: "POST",
			data: {
				...params
			}
		});
	}
}
export default Goods;
