import { defineStore } from "pinia";
import { ref } from "vue";
import { storage } from "/@/cool/utils";

const DEFAULT_TIMEZONE = "Asia/Shanghai";
const data = storage.info();
export const useTimezoneStore = defineStore("timezone", function () {
	// 基本信息
	const info = ref<String>(data.timezone || DEFAULT_TIMEZONE);
	// 设置基本信息
	function set(data: string) {
		info.value = data;
		console.log("data", data);
		storage.set("timezone", info.value);
	}

	function get(): string {
		return info.value || "";
	}

	return {
		info,
		set,
		get
	};
});
