import { BaseService, Service } from "/@/cool";
@Service("wlSpecification")
class WlSpecification extends BaseService {
	getAll(data: {}) {
		return this.request({
			url: "/getAll",
			method: "POST",
			data
		});
	}
}
export default WlSpecification;
