import { BaseService, Service } from "/@/cool";
@Service("orderCompose")
class OrderCompose extends BaseService {
	constructor() {
		super();

		let prefix = "";
		if (this.namespace) {
			prefix = this.namespace.replace(/\//g, ":") + ":";
		}
		// this.permission["makeOrderCheck"] = prefix + "makeOrderCheck";
		// this.permission["makeOrderV2"] = prefix + "makeOrderV2";
		// this.permission["makeQueueOrderV2"] = prefix + "makeQueueOrderV2";
		// this.permission["goods"] = prefix + "goods";
		// this.permission["goodsV2"] = prefix + "goodsV2";
		// this.permission["makeAddPrintOrderV2"] = prefix + "makeAddPrintOrderV2";
		this.permission["add"] = prefix + "add";
		this.permission["page"] = prefix + "page";
		this.permission["getDetail"] = prefix + "getDetail";
		this.permission["cancelOrderComposer"] = prefix + "cancelOrderComposer";
		this.permission["pageSuitOrderCompose"] = prefix + "pageSuitOrderCompose";
	}

	getDetail(data:any){
		return this.request({
			url: "/getDetail",
			method: "POST",
			data
		});
	}

	cancelOrderComposer(data:any){
		return this.request({
			url: "/cancelOrderComposer",
			method: "POST",
			data
		});
	}

	pageSuitOrderCompose(data:any){
		return this.request({
			url: "/pageSuitOrderCompose",
			method: "POST",
			data
		});
	}


}
export default OrderCompose;
