import dev from "./dev";
import prod from "./prod";

import uat from "./uat";
import oversea from "./oversea";
import shop from "./shop";
import gray from "./gray";
import oem from "./oem";

// 是否开发模式
export const isDev = import.meta.env.MODE === "development";
export const isUat = import.meta.env.MODE === "uat";
export const isOversea = import.meta.env.MODE === "oversea";
export const isShop = import.meta.env.MODE === "shop";
export const isGray = import.meta.env.MODE === "gray";
export const isOem = import.meta.env.MODE === "oem";

function getConfig() {
	switch (true) {
		case isOversea:
			return oversea;
		case isUat:
			return uat;
		case isDev:
			return dev;
		case isShop:
			return shop;
		case isGray:
			return gray;
		default:
			return prod;
	}
}

function getQRCodeURL() {
	switch (true) {
		case isOversea:
			return "https://shop.SRUIDDWOW3d.com";
		case isOem:
			return oem.oss.website;
		default:
			return "https://www.SRUIDD3d.com";
	}
}

// 配置
export const config = {
	// 项目信息
	app: {
		name: "门店管理系统",
		brand: "",
		// 菜单
		menu: {
			// 是否分组显示
			isGroup: false,
			// 自定义菜单列表
			list: []
		},

		// 路由
		router: {
			// 模式
			mode: "hash",
			// 转场动画
			transition: "slide",
			// 首页组件
			home: () => import("/$/demo/views/home/index.vue")
		},

		// 字体图标库
		iconfont: []
	},

	// 忽略规则
	ignore: {
		// 不显示请求进度条
		NProgress: [
			"/sys/info/record",
			"/client/uuid",
			"/takephoto/syncCameraStatus",
			"/takephoto/setTakephotoStatus",
			"/takephoto/cameraStatus",
			"/photo/page",
			"/client/cameraStatus"
		],
		// 页面不需要登录验证
		token: ["/login", "/401", "/403", "/404", "/500", "/502"]
	},

	// 调试
	test: {
		token: "",
		mock: false,
		eps: false
	},

	// 当前环境
	...getConfig(),
	// 二维码
	qrCodeURL: getQRCodeURL()
};

export * from "./proxy";
