import { proxy } from "./proxy";

export default {
	host: proxy["/uat"].target,
	// 请求地址
	baseUrl: "https://shop.api.sharewow3d.com/admin/",
	oss: {
		website: "https://www.suwa3d.com",
		"3dview": "https://3dview.SRsuwa3dUIDD3d.com",
		webwechat: "https://wechat.suwa3d.com",
		securedata: "https://wechat.suwa3d.com"
	},
	msgWebsocket: {
		WEBSOCKETADDRESS: "wss://shop.api.sharewow3d.com"
	},
	weightWs: "ws://localhost:10999/ws",
	menuIgnore: [
		"menu_customer_management", 
		"menu_card_voucher_management", 
		"menu_material_mall", 
		"menu_operations_management", 
		"menu_customer_conversion_rate",
		"menu_device_controller",
		"menu_device_check",
		"menu_equipment_debug",
	],
	watermask: {
		title: "SHAREWOW"
	}
};
