import { BaseService, Service } from "/@/cool";
@Service("wechatQueueTakephoto")
class WechatQueueTakephoto extends BaseService {
	constructor() {
		super();

		let prefix = "";
		if (this.namespace) {
			prefix = this.namespace.replace(/\//g, ":") + ":";
		}

		// this.permission["makeOrder"] = prefix + "makeOrder";
		this.permission["goodsV2"] = prefix + "goodsV2";
	}

	// 商品
	goods(params: any) {
		return this.request({
			url: "/goods",
			method: "POST",
			data: {
				...params
			}
		});
	}

	// 商品
	goodsV2(params: any) {
		return this.request({
			url: "/goodsV2",
			method: "POST",
			data: {
				...params
			}
		});
	}
}
export default WechatQueueTakephoto;
