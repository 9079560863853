import { BaseService, Service } from "/@/cool";
@Service("couponTracking")
class CouponTracking extends BaseService {
	constructor() {
		super();

		let prefix = "";
		if (this.namespace) {
			prefix = this.namespace.replace(/\//g, ":") + ":";
		}
		// this.permission["infoCouponTracking"] = prefix + "infoCouponTracking";
		// this.permission["infoCouponTrackingByStore"] = prefix + "infoCouponTrackingByStore";
	}

	// InfoCouponTracking(params: any) {
	// 	return this.request({
	// 		url: "/infoCouponTracking",
	// 		method: "POST",
	// 		data: {
	// 			...params
	// 		}
	// 	});
	// }

	// InfoCouponTrackingByStore(params: any) {
	// 	return this.request({
	// 		url: "/infoCouponTrackingByStore",
	// 		method: "POST",
	// 		data: {
	// 			...params
	// 		}
	// 	});
	// }
}
export default CouponTracking;
