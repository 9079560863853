import { BaseService, Service, Permission } from "/@/cool";

@Service("baseSysLog")
class SysLog extends BaseService {
	constructor() {
		super();

		let prefix = "";
		if (this.namespace) {
			prefix = this.namespace.replace(/\//g, ":") + ":";
		}

		this.permission["add"] = prefix + "add";
		this.permission["update"] = prefix + "update";
		this.permission["page"] = prefix + "page";
		this.permission["list"] = prefix + "list";
		this.permission["info"] = prefix + "info";
		this.permission["clear"] = prefix + "clear";
		this.permission["getKeep"] = prefix + "getKeep";
		this.permission["setKeep"] = prefix + "setKeep";
	}

	@Permission("clear")
	clear(data: any) {
		return this.request({
			url: "/clear",
			method: "POST",
			data
		});
	}

	@Permission("getKeep")
	getKeep() {
		return this.request({
			url: "/getKeep"
		});
	}

	@Permission("setKeep")
	setKeep(value: any) {
		return this.request({
			url: "/setKeep",
			method: "POST",
			data: {
				value
			}
		});
	}
}

export default SysLog;
