import { BaseService, Service } from "/@/cool";
@Service("deleteSoftOrder")
class DeleteSoftOrder extends BaseService {
    constructor() {
		super();
		let prefix = "";
		if (this.namespace) {
			prefix = this.namespace.replace(/\//g, ":") + ":";
		}
		this.permission["delete"] = prefix + "delete";
		this.permission["deleteByWxAuth"] = prefix + "deleteByWxAuth";
		this.permission["isCanDelete"] = prefix + "isCanDelete";
	}

	//判断是否可以删除
	isCanDelete(params: any) {
		return this.request({
			url: "/isCanDelete",
			method: "POST",
			data: {
				...params
			}
		});
	}


    //软删除订单
	delete(params: any) {
		return this.request({
			url: "/delete",
			method: "POST",
			data: {
				...params
			}
		});
	}
	//微信验证删除
	deleteByWxAuth(params: any) {
		return this.request({
			url: "/deleteByWxAuth",
			method: "POST",
			data: {
				...params
			}
		});
	}
}
export default DeleteSoftOrder;
