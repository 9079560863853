import { BaseService, Service } from "/@/cool";
@Service("orderTracking")
class OrderTracking extends BaseService {
	constructor() {
		super();

		let prefix = "";
		if (this.namespace) {
			prefix = this.namespace.replace(/\//g, ":") + ":";
		}
		this.permission["infoOrderTracking"] = prefix + "infoOrderTracking";
		this.permission["infoOrderTrackingByStore"] = prefix + "infoOrderTrackingByStore";
	}

	InfoOrderTracking(params: any) {
		return this.request({
			url: "/infoOrderTracking",
			method: "POST",
			data: {
				...params
			}
		});
	}

	InfoOrderTrackingByStore(params: any) {
		return this.request({
			url: "/infoOrderTrackingByStore",
			method: "POST",
			data: {
				...params
			}
		});
	}
}
export default OrderTracking;
