import { BaseService, Service } from "/@/cool";
// const localHttpPrefix = "https://192.168.88.2:8200";

@Service("shoot")
class Shoot extends BaseService {
	constructor() {
		super();

		let prefix = "";
		if (this.namespace) {
			prefix = this.namespace.replace(/\//g, ":") + ":";
		}

		this.permission["page"] = prefix + "page";
		this.permission["addUploadQueue"] = prefix + "addUploadQueue";
		this.permission["photos"] = prefix + "photos";
		this.permission["check"] = prefix + "check";
		this.permission["shakeCheckImage"] = prefix + "shakeCheckImage";
		this.permission["preview"] = prefix + "preview";
	}

	page(data: any) {
		return this.request({
			url: "/page",
			method: "POST",
			data
		});
	}

	addUploadQueue(data: any) {
		return this.request({
			url: "/addUploadQueue",
			method: "POST",
			data
		});
	}

	photos(data: any) {
		return this.request({
			url: "/photos",
			method: "POST",
			data
		});
	}
	// 审核
	check(data: any) {
		return this.request({
			url: "/check",
			method: "POST",
			data
		});
	}

	shakeCheckImage(data: {}) {
		return this.request({
			url: "/shakeCheckImage",
			method: "POST",
			data: {
				...data
			}
		});
	}

	preview(data: {}) {
		return this.request({
			url: "/preview",
			method: "POST",
			data: {
				...data
			}
		});
	}
}
export default Shoot;
