import { defineStore } from "pinia";
import { ref } from "vue";
import { storage } from "/@/cool/utils";
const data = storage.info();

const ZHCN = "zh-CN";
const EN = "en";

export const useLangStore = defineStore("lang", function () {
	// 基本信息
	const info = ref<String>(data.lang || ZHCN);
	const list = ref<String[]>([ZHCN, EN]);
	// 设置基本信息
	function set(data: string) {
		info.value = data;
		storage.set("lang", info);
	}

	function get() {
		return info.value;
	}

	function isZh() {
		return info.value === ZHCN;
	}

	function switchLang() {
		if (info.value == ZHCN) {
			set(EN);
		} else {
			set(ZHCN);
		}
	}

	return {
		info,
		set,
		list,
		isZh,
		get,
		switchLang
	};
});
