import { BaseService, Service } from "/@/cool";
@Service("wlGoodsCategory")
class WlGoodsCategory extends BaseService {
	constructor() {
		super();
		let prefix = "";
		if (this.namespace) {
			prefix = this.namespace.replace(/\//g, ":") + ":";
		}

		this.permission["getAll"] = prefix + "getAll";
	}

	getAll(data: {}) {
		return this.request({
			url: "/getAll",
			method: "POST",
			data
		});
	}
}
export default WlGoodsCategory;
