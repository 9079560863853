import { BaseService, Service } from "/@/cool";
@Service("operationEnteringStore")
class OperationEnteringStore extends BaseService {
	constructor() {
		super();

		let prefix = "";
		if (this.namespace) {
			prefix = this.namespace.replace(/\//g, ":") + ":";
		}

		this.permission["add"] = prefix + "add";
		this.permission["page"] = prefix + "page";
		this.permission["list"] = prefix + "list";
	}

	Add(params: any) {
		return this.request({
			url: "/add",
			method: "POST",
			data: {
				...params
			}
		});
	}
}

export default OperationEnteringStore;
