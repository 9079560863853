export const proxy = {
	"/dev": {
		target: "http://127.0.0.1:20001",
		changeOrigin: true,
		rewrite: (path: string) => {
			console.log("path:", path);
			return path.replace(/^\/dev/, "admin");
		}
	},

	"/prod": {
		target: "https://mp.api.suwa3d.com/admin",
		changeOrigin: true,
		rewrite: (path: string) => path.replace(/^\/pro/, "/admin")
	},

	"/oem": {
		target: "https://mp.api.suwa3d.com/admin",
		changeOrigin: true,
		rewrite: (path: string) => path.replace(/^\/pro/, "/admin")
	},

	"/uat": {
		target: "http://192.168.2.10:20001",
		changeOrigin: true,
		rewrite: (path: string) => path.replace(/^\/uat/, "/admin")
	},

	"/oversea": {
		target: "http://sharewow3d.com:18199",
		changeOrigin: true,
		rewrite: (path: string) => path.replace(/^\/oversea/, "/admin")
	},
	"/shop": {
		target: "https://shop.api.suwa3d.com",
		changeOrigin: true,
		rewrite: (path: string) => path.replace(/^\/shop/, "/admin")
	}
};
