export function usFormat(value: any): string {
	if (typeof value === "number") {
		return value.usFormat;
	}
	return Number(value).toLocaleString("en-US");
}

Object.defineProperty(Number.prototype, "usf", {
	get() {
		return this.toLocaleString("en-US");
	}
});

Object.defineProperty(String.prototype, "usf", {
	get() {
		return usFormat(this);
	}
});

const toFixed = Number.prototype.toFixed;

export { toFixed };

Number.prototype.$toFixed = function (fractionDigits?: number) {
	const ret = toFixed.call(this, fractionDigits);
	return usFormat(ret);
};

Object.isNull = function isNull(val: any) {
	return val === null || val === undefined || val == "";
};
