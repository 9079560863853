import { proxy } from "./proxy";

export default {
	host: proxy["/shop"].target,
	// 请求地址
	baseUrl: "https://shop.api.suwa3d.com/admin",
	oss: {
		website: "https://www.suwa3d.com",
		"3dview": "https://3dview.suwa3d.com",
		webwechat: "https://www.suwa3d.com",
		securedata: "https://wechat.suwa3d.com"
	},
	msgWebsocket: {
		WEBSOCKETADDRESS: "wss://shop.api.suwa3d.com"
	},
	weightWs: "ws://localhost:10999/ws",
	menuIgnore: [],
	wechatApi: "https://wechat.api.suwa3d.com",
	watermask: {
		title: "速哇3D真人手办"
	}
};
